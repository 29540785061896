@import url('https://fonts.googleapis.com/css?family=Dancing+Script|Open+Sans|Mitr:300,400,500|Sarabun:400,500,600');
html,body{font-family: "Open Sans", Arial, Helvetica, sans-serif;font-size: 14px;color: #000;padding: 0;margin: 0;}
img{max-width: 100%;}
main{position: relative;padding-left: 265px;display: block;padding-top: 15px;padding-right: 15px;transition: .3s all;}
main.hide{padding-left: 55px;transition: .1s all;}
a{color: #333;text-decoration: underline}
a:hover{color: #000;text-decoration: underline}
.mr5{margin-right: 5px;}
.mt30{margin-top: 30px;}
.mt5{margin-top: 5px;}
.mt10{margin-top: 10px;}
.pl15{padding-left: 15px;}
.pr15{padding-right: 15px;}
.dimb{display: inline-block;vertical-align: middle}
.text-center{text-align: center}
.borderBottom{border-bottom: 1px solid #ccc;width: 150px}
.ant-form .borderBottom{text-align: right !important}
.btn-delete{color: white !important; background: #c33; border: 1px solid #c33; font-size: 12px; padding: 4px 15px;font-weight: bold;cursor: pointer;}
.btn-edit{background: #f9f9f9;color: #444; padding: 3px 10px 4px;border: 1px solid #ccc;width: 60px;cursor: pointer}
.btn-edit:hover{background: #fff;color: #333;border-color: #999}
.btn-delete:hover{background: #ef3131;border:1px solid #ef3131}
.btn-save{background: #069;color: white;border: 1px solid #069;padding: 3px 10px 4px;width: 60px;cursor: pointer;}
.btn-submit{background: #069;color: white;border: 1px solid #069;padding: 5px 20px 6px;cursor: pointer;height: auto;font-size: 16px;width: 100%;border-radius: 0}
.btn-submit:hover{background: #036;border-color: #036}
.btn-cancel{background: #eee;color: #333;border: 1px solid #ccc;padding: 3px 10px 4px;width: 60px;cursor: pointer;}
.dimb{display: inline-block;vertical-align: middle}
.fwn{font-weight: normal;}
.btn-print{background: #036;color:white;border: 1px solid transparent;width: 140px;border-radius: 0;text-align: center;color:white !important;padding: 7px 10px 6px;height: auto;font-weight: bold}
.btn-print *{color: white !important}
.btn-print:hover,.btn-print:focus{background:#058!important;border-color:transparent !important}
.nl2br{white-space: pre-line}
.btn-default{background: #f9f9f9;color:#333;border: 1px solid #999;padding: 2px 15px 6px;cursor: pointer;}
.btn-default:hover{background: #fff;color: #000;border-color: #333}
.customerName{font-size: 20px;margin-top: -30px;margin-bottom: 5px;}
button>a{text-decoration: none}
.date-thai{color: red; font-size: 20px; position: absolute; top: 24px; left: 0; right: 0; margin: 0 auto; text-align: center;}
.date-thai>span{display: inline-block;color: red !important;border: 2px solid red;padding: 5px 10px;}
.switch-date .ant-switch{position: relative !important;top: initial !important;right: initial !important}
.col-2{display: grid;grid-template-columns: 50% 50%}
.col-2 .ant-calendar-picker{width: 100%}

/* ANTD
======================================= */
.ant-table-thead>tr>th{text-align: center;font-size: 14px;background: #393939;color:white;}
.ant-table-tbody>tr>td{font-size: 13px;}
.ant-table-tbody>tr.ant-table-row-hover>td, .ant-table-tbody>tr:hover>td, .ant-table-thead>tr.ant-table-row-hover>td, .ant-table-thead>tr:hover>td{background: rgba(255,255,0,.03);}
.ant-table-bordered .ant-table-body>table, .ant-table-bordered .ant-table-fixed-left table, .ant-table-bordered .ant-table-fixed-right table, .ant-table-bordered .ant-table-header>table{border-color: rgba(0,0,0,.2)}
.ant-table-bordered .ant-table-tbody>tr>td, .ant-table-bordered .ant-table-thead>tr>th{border-color: rgba(0,0,0,.2) !important}
.ant-input{height: auto;padding: 7.5px;border-radius: 0}
.ant-table-middle>.ant-table-content>.ant-table-body>table>.ant-table-tbody>tr>td, .ant-table-middle>.ant-table-content>.ant-table-body>table>.ant-table-thead>tr>th, .ant-table-middle>.ant-table-content>.ant-table-fixed-left>.ant-table-body-outer>.ant-table-body-inner>table>.ant-table-tbody>tr>td, .ant-table-middle>.ant-table-content>.ant-table-fixed-left>.ant-table-body-outer>.ant-table-body-inner>table>.ant-table-thead>tr>th, .ant-table-middle>.ant-table-content>.ant-table-fixed-left>.ant-table-header>table>.ant-table-tbody>tr>td, .ant-table-middle>.ant-table-content>.ant-table-fixed-left>.ant-table-header>table>.ant-table-thead>tr>th, .ant-table-middle>.ant-table-content>.ant-table-fixed-right>.ant-table-body-outer>.ant-table-body-inner>table>.ant-table-tbody>tr>td, .ant-table-middle>.ant-table-content>.ant-table-fixed-right>.ant-table-body-outer>.ant-table-body-inner>table>.ant-table-thead>tr>th, .ant-table-middle>.ant-table-content>.ant-table-fixed-right>.ant-table-header>table>.ant-table-tbody>tr>td, .ant-table-middle>.ant-table-content>.ant-table-fixed-right>.ant-table-header>table>.ant-table-thead>tr>th, .ant-table-middle>.ant-table-content>.ant-table-header>table>.ant-table-tbody>tr>td, .ant-table-middle>.ant-table-content>.ant-table-header>table>.ant-table-thead>tr>th, .ant-table-middle>.ant-table-content>.ant-table-scroll>.ant-table-body>table>.ant-table-tbody>tr>td, .ant-table-middle>.ant-table-content>.ant-table-scroll>.ant-table-body>table>.ant-table-thead>tr>th, .ant-table-middle>.ant-table-content>.ant-table-scroll>.ant-table-header>table>.ant-table-tbody>tr>td, .ant-table-middle>.ant-table-content>.ant-table-scroll>.ant-table-header>table>.ant-table-thead>tr>th, .ant-table-middle>.ant-table-footer, .ant-table-middle>.ant-table-title{padding: 5px 10px}
.ant-form-item-label{line-height: initial;margin-bottom: 3px;}
.ant-select-selection--single{padding: 2.5px;border-radius: 0;height: auto;}
.ant-form>div:last-child{margin-bottom: 5px;}
.ant-modal-content{border-radius: 0;width:900px;margin: 0 auto}
.ant-modal.quatationModal{width:100% !important;}
.ant-modal-footer button.ant-btn-primary{background: #09c;color: white;padding: 5px 20px;font-weight: bold;border-radius: 0;width: 120px}
.ant-modal-footer button.ant-btn{border-radius: 0}
.ant-table-thead>tr>th{white-space: nowrap}


/* ASIDE
======================================= */
aside{background: #001529;height: 100%;width: 240px;position: fixed;left: 0;top: 0;z-index:1000;transition: .3s all;}
aside>p{font-size: 44px;color: white;text-align: center;font-family: "Dancing Script";padding: 10px 0 20px;margin: 0 0 15px;position: relative;}
aside>p>span{font-size: 16px;display: block;position: absolute;bottom: 10px;left: 0;right: 0;margin: 0 auto;}
aside nav>ul{list-style: none;margin: 0;padding: 0}
aside nav>ul>li{display: block;padding: 0;margin: 0 0 1px;color: rgba(255,255,255,.9);background: rgba(116, 116, 116, 0.1);position: relative;}
aside nav>ul>li>a,aside nav>ul>li>button{display: block;padding: 10px 15px;color: inherit !important;position: relative;background: transparent;border: 0;width: 100%;text-align:left;cursor: pointer;font-size: 14px;}
aside nav>ul>li>a>i,aside nav>ul>li>button>i{display: inline-block;vertical-align: middle;margin-right: 10px;font-size: 20px;}
aside nav>ul>li>a>span,aside nav>ul>li>button>span{display: inline-block;vertical-align: middle}
aside nav>ul>li:hover,aside nav>ul>li:focus,aside nav>ul>li:active{color: white;background: rgba(255,255,255,.2);}
aside nav>ul>li.submenu>a:after{content: '▾';position: absolute;top: 10px;right: 10px;font-size: 15px;}
aside nav>ul>li.submenu.active>a:after{content: '▴'}
aside nav>ul>li.active,aside nav>ul>li.active:hover,aside nav>ul>li.active:focus,aside nav>ul>li.active:active{background: tomato;color: #fff}
aside.hide{left: 0;width: 40px;transition: .1s all;}
aside.hide nav>ul>li>a span{display: none;}
aside.hide nav>ul>li>a{padding: 10px 5px;text-align: center;line-height: 14px;}
aside.hide>p>span{display: none;}
aside.hide>p{opacity: 0;height: 35px;}
aside.hide nav>ul>li>button{padding: 10px 5px;text-align: center;line-height: 14px;}
aside.hide nav>ul>li>a>i, aside.hide nav>ul>li>button>i{margin-right: 0;}
aside.hide nav>ul>li>button span{display: none;}
#btn-aside{position: absolute;bottom: 30px;right:-20px;font-size: 17px;color:white;background: transparent;border: 0;cursor: pointer;transition: .1s all;outline: none !important;padding: 2px 3px;border-radius: 0 4px 4px 0;background: #001529;}
#btn-aside:hover{color: orange;transition: .3s all;}


/* CONTAINER
======================================== */
#container{min-height: 97vh}
#top-bar{padding: 10px 0;text-align: left}
.btn-add{font-size: 13px;padding: 5px 15px;color: #333;cursor: pointer;}
.btn-add>i{margin-right: 5px;display: inline-block;vertical-align: middle}
.btn-add>span{display: inline-block;vertical-align: middle}
#project-table .ant-table-thead>tr>th:not(:nth-child(3)):not(:nth-child(4)):not(:nth-child(5)){width: 1%;white-space: nowrap;text-align: center}
#project-table .ant-table-thead>tr:last-child>th{white-space: nowrap}
#project-table .ant-input{min-width: 80px}
#project-table .ant-table-tbody>tr>td{vertical-align: top}
#project-table .ant-table-tbody>tr>td:not(:nth-child(3)):not(:nth-child(4)):not(:nth-child(5)){width: 1%;white-space: nowrap;text-align: center}
#project-table .ant-table-tbody>tr>td:nth-child(10)>p{color: #090}
#project-table .ant-table-tbody>tr>td:nth-child(11)>p{color: #c33}

#project-table .ant-table-tbody>tr>td:nth-child(3)>a{text-decoration: none;font-weight: bold;}
#project-table .ant-table-tbody>tr>td:nth-child(3)>a>span{color: #036;text-decoration: underline;font-weight: normal;}
#project-table .ant-table-tbody>tr>td:nth-child(5)>.transaction-action:not(:last-child){margin-bottom: 5px;}
/* #project-table .transaction-action{margin-bottom: 4px;} */
#project-table .transaction-action>div.qt{display: grid;grid-template-columns: auto 35px;position: relative;}
#project-table .transaction-action>div.qt>button{background: #09f;color: white;border: 1px solid transparent;padding: 2px 5px 3px;cursor: pointer;border: 1px solid transparent;height: 28px;white-space: nowrap;font-size: 12px;}
#project-table .transaction-action>div.qt>button:hover{background: #31abfd;}
#project-table .transaction-action>div.qt>a{border:1px solid transparent;background: #069;color: #fff;padding: 2px 5px 0 5px;opacity: 1;text-align: center;}
#project-table .transaction-action>div.qt>a:hover{background: #036;;}
#project-table .transaction-action.full-width>div{grid-template-columns: auto;}
#project-table .transaction-action.full-width>div>button{background: #f9f9f9;border:1px solid #ccc;color: #777;padding-right: 5px}
#project-table .transaction-action.full-width>div>button:hover{background: #fff;border-color: #999;color: #333}

#project-table .transaction-action.twoRow{height: 64px}
#project-table .transaction-action.many{height: 65px;margin-bottom: 4px;position: relative;}
#project-table .transaction-action.inv.many:after{content: ''; width: 2px; background: #000; position: absolute; top: 13px; left: -11px; height: 35px;}
#project-table .transaction-action.inv.many:before{content: ''; width: 10px; background: #000; position: absolute; top: 46px; left: -11px; height: 2px;}
#project-table .transaction-action>div.invoice{position: relative;padding:0;}
#project-table .transaction-action>div.invoice .item{display: grid;grid-template-columns: auto 35px;margin: 0 0 5px 0;width: 130px;position: relative;}
#project-table .transaction-action>div.invoice .item.new{display: grid;grid-template-columns: auto;position: relative;}
#project-table .transaction-action>div.invoice .item>button{background: orange;color: white;border: 1px solid transparent;padding: 1px 5px 3px;cursor: pointer;outline: none !important;box-shadow: none !important;height: 28px;}
#project-table .transaction-action>div.invoice .item>button:hover{background: #ffba3a;}
#project-table .transaction-action>div.invoice .item>button+a{display: inline-block;background: #da7a02;color: white;font-size: 14px;padding: 1px 0 2px;border: 1px solid transparent;border-left: 0}
#project-table .transaction-action>div.invoice .item>button+a:hover{background: #a95e01;}
#project-table .transaction-action>div.invoice>div{display: grid;grid-template-columns: 50% 50%;position: relative;}
#project-table .transaction-action>div.invoice>div:not(.new):after{content: ''; position: absolute; left: 100%; top: 12px; background: #000;width: 21px;height: 2px;}
#project-table .transaction-action:not(.full-width)>div.qt:after{content: ''; position: absolute; left: 100%; top: 12px; background: #000;width: 21px;height: 2px;}


#project-table .transaction-action>div.invoice>div>div.item:last-child>button{background: orange}
#project-table .transaction-action>div.invoice>div>div.item:last-child>button:hover{background: #ffb328}
#project-table .transaction-action>div.invoice>div>div.item:last-child>button+a{background:#a95e01}
#project-table .transaction-action>div.invoice>div>div.item:last-child>button+a:hover{background:#945303}
#project-table .transaction-action>div.invoice>div.new{grid-template-columns: auto;margin-bottom: 5px;}
#project-table .transaction-action>div.invoice>div.new>button{margin-bottom: 0;border: 1px solid #ccc;background: #f9f9f9;box-shadow: none;padding: 1px 10px 4px;height: 28px;}
#project-table .transaction-action>div.invoice>div.new>button:hover{background: #fff;border-color: #999;cursor: pointer;color: #000}

#project-table .transaction-action>div.receive{position: relative;padding:0;}
#project-table .transaction-action>div.receive .item{display: grid;grid-template-columns: auto 35px;margin: 0 0 5px 0;width: 130px;position: relative;}
#project-table .transaction-action>div.receive .item.new{display: grid;grid-template-columns: auto;position: relative;}
#project-table .transaction-action>div.receive .item>button{background: orange;color: white;border: 1px solid transparent;padding: 1px 5px 3px;cursor: pointer;outline: none !important;box-shadow: none !important;height: 28px;}
#project-table .transaction-action>div.receive .item>button:hover{background: #ffba3a;}
#project-table .transaction-action>div.receive .item>button+a{display: inline-block;background: #da7a02;color: white;font-size: 14px;padding: 1px 0 2px;border: 1px solid transparent;border-left: 0}
#project-table .transaction-action>div.receive .item>button+a:hover{background: #a95e01;}
#project-table .transaction-action>div.receive>div{display: grid;grid-template-columns: 50% 50%}
#project-table .transaction-action>div.receive>div>div.item:last-child>button{background: #090}
#project-table .transaction-action>div.receive>div>div.item:last-child>button:hover{background: #07ab07}
#project-table .transaction-action>div.receive>div>div.item:last-child>button+a{background: green}
#project-table .transaction-action>div.receive>div>div.item:last-child>button+a:hover{background:#037503}
#project-table .transaction-action>div.receive>div.new{grid-template-columns: auto;}
#project-table .transaction-action>div.receive>div.new>button{margin-bottom: 5px;border: 1px solid #ccc;background: #f9f9f9;box-shadow: none;padding: 1px 10px 4px;height: 28px;}
#project-table .transaction-action>div.receive>div.new>button:hover{background: #fff;border-color: #999;cursor: pointer;color: #000}

#project-table .ant-table-tbody>tr>td:nth-child(7)>a{display: block;width:50px;padding: 3px;background: #069;color: white;margin: 0 0 4px;text-decoration:none;font-size: 12px;min-width: 20px;text-align: center}
#project-table .ant-table-tbody>tr>td:nth-child(7)>a:hover{background: 036;}
#project-table .ant-table-tbody>tr>td:nth-child(7)>a:last-child{background: #eee;color: #999}
#project-table .ant-table-tbody>tr>td:nth-child(7)>a:last-child:hover{background: #e6e6e6;color: #666}
#project-table .ant-table-tbody>tr>td:nth-child(8)>a{display: block;width:50px;padding: 3px;background: #090;color: white;margin: 0 0 4px;text-decoration:none;font-size: 12px;min-width: 20px;text-align: center}
#project-table .ant-table-tbody>tr>td:nth-child(8)>a:hover{background: green;}
#project-table .ant-table-tbody>tr>td:nth-child(8)>a:last-child{background: #eee;color: #999}
#project-table .ant-table-tbody>tr>td:nth-child(8)>a:last-child:hover{background: #e6e6e6;color: #666}
/* #project-table .ant-table-tbody>tr>td:nth-child(10){font-weight: bold;color: #111;}
#project-table .ant-table-tbody>tr>td:nth-child(11){font-weight: bold;color: green;}
#project-table .ant-table-tbody>tr>td:nth-child(12){font-weight: bold;color: #c33;} */
#project-table .ant-table-tbody>tr>td:nth-child(2)>span{padding: 3px 5px 4px;width: 80px;display: inline-block;font-size: 12px;}
#project-table .ant-table-tbody>tr.editable-row>td:nth-child(2) .ant-form-item-control span.ant-form-item-children{padding: 0;width: auto}
#project-table .ant-table-tbody>tr>td:nth-child(2) span.close{background: #eee;color: #bbb}
#project-table .ant-table-tbody>tr>td:nth-child(2) span.burning{background: #090;color: #fff}
#project-table .ant-table-tbody>tr>td:nth-child(2) span.service{background: #ccc;color: #333}
#project-table .ant-table-tbody>tr>td:nth-child(2) span.open{background: yellow;color: #000}
#project-table .ant-table-tbody>tr>td:nth-child(2) span.status>*{display: inline-block;vertical-align: middle}
#project-table .ant-table-tbody>tr>td:nth-child(2) span.status>i{margin-right: 5px;}
#project-table .ant-table-tbody>tr>td:first-child .anticon-form{font-size: 27px;margin-right: 7px;color: #036;cursor: pointer;opacity: .5;}
#project-table .ant-table-tbody>tr>td:first-child .anticon-delete{font-size: 27px;color: #c33;cursor: pointer;opacity: .5;}
#project-table .ant-table-tbody>tr>td:first-child .anticon-delete:hover,#project-table .ant-table-tbody>tr>td:first-child .anticon-form:hover{opacity: 1;}
#project-table .ant-table-tbody>tr>td p{margin-bottom: 0;}
#project-table .ant-table-tbody>tr>td .expire{background: #fff;cursor: pointer;padding: 2px 10px;text-decoration: none;display: inline-block}
#project-table .ant-table-tbody>tr>td .expire:hover,#project-table .ant-table-tbody>tr>td .expire:focus{outline: none;box-shadow: none;}
#project-table .ant-table-tbody>tr>td .normal{color: #999;border: 1px solid #ccc;}
#project-table .ant-table-tbody>tr>td .expired{color: red;font-weight: bold;text-decoration: line-through}
#project-table .ant-table-tbody>tr>td .coming{color: orangered;font-weight: bold;border: 2px solid red;}
#project-table .ant-table-tbody>tr>td p.net{font-size: 14px;border:1px solid transparent;margin: 0 0 5px;font-weight: bold;text-align: right;padding-top: 2px;border-bottom: 1px solid #e9e9e9;padding-bottom: 3px;}
#project-table .ant-table-tbody>tr>td p.net.many{height: 60px;display: block}

#customer-table tr>th:last-child{width: 1%;white-space: nowrap;}
#customer-table tr>td:last-child{width: 1%;white-space: nowrap;text-align: center}
#customer-table tr>td:last-child i{font-size: 20px;cursor: pointer;}
#customer-table tr>td:nth-last-child(2){width: 1%;white-space: nowrap}
#customer-table tr>td:nth-last-child(2) button{padding-bottom: 4px;}


#quatation {font-size: 15px;font-family: Sarabun,sans-serif;color: #000}
#quatation * {color: #000}
#quatation .col2{display: grid;grid-template-columns: max-content auto;align-items: top}
#quatation .ant-input{border: 0;border-bottom: 1px solid #ccc;height: auto;padding: 0 0 1px 2px;color: blue}
#quatation input:read-only{color:#000 !important;}
#quatation input:read-only:hover{border-color: #ccc}
#quatation input.ant-calendar-picker-input:hover{border-color: blue}
#quatation .ant-input:focus,#quatation .ant-input:active,#quatation .ant-input:hover{outline: none;box-shadow: none;border-color: blue;}
#quatation .ant-form-item-control{line-height: initial}
#quatation .ant-form-item{margin-bottom: 10px;}

#quatation #head{display: grid;grid-template-columns: auto 240px;align-items: top}
#quatation.hosting #head{grid-template-columns: auto max-content;}
#quatation.receive #head{grid-template-columns: auto 290px;}
.ant-modal-body #quatation #head{display: grid;grid-template-columns: auto max-content;align-items: top}
#quatation #head .right{padding-left: 50px;padding-top: 10px;text-align: right}
#quatation #head .right .ant-input{text-align: right}
#quatation.hosting #head .right .ant-input{text-align: left}
#quatation #head .right .ant-select-selection--single{padding: 0 0 0 10px;border:0;border-bottom: 1px solid #ccc;line-height: initial;box-shadow: none !important;width: 150px}
#quatation #head .right .ant-select-selection__rendered{margin: 0;line-height: initial}
#quatation #head .right .ant-select-selection-selected-value{float: right;padding-right: 0;color: blue}
#quatation #head .right .ant-select-arrow{display: none;}
#quatation #head2{margin-top: 15px;margin-bottom: 10px;}
#quatation.receive #head2{margin-top: 10px;}
#quatation #head2.col2{grid-template-columns: 60% auto}
#quatation #head2.col2 .col2{grid-template-columns: 60px auto}
#quatation #head2.col2 .col2 p{margin-bottom: 5px;}
#quatation #head2 p.mb0{margin-bottom: 0;}
#quatation #head2 p.mb5{margin-bottom: 5px}
#quatation #head2.col2>div:first-child .col2{grid-template-columns: 175px auto}
#quatation.hosting #head .right .ant-input{text-align: left}
#quatation.receive #head.logo .left{margin-top: 15px;}
#quatation.receive #head .left img{margin-bottom: 21px;}
#quatation.invoices #head .left img{margin: 15px 0 21px;}
#quatation.quatation #head .left img{margin: 15px 0 21px;}
#quatation.hosting #head .left img{margin: 15px 0 56px;}
.pt75{padding-top: 75px;}

#quatation #head h1{font-family: "Mitr";font-size: 36px;font-weight: 400;text-align: left;margin-bottom: 35px;line-height: 35px}
#quatation #head h1>span{display: block;font-size: 20px;text-transform: uppercase;}
#quatation #head .left>div>p{padding: 0;margin-bottom: 0;}
#quatation.receive #head .left>div>p{margin-bottom: 5px;}
#quatation #head .left>div>div>p{margin-bottom: 5px;}
#quatation #head .left .col2{grid-template-columns: 90px auto}
#quatation #head.en .left .col2{grid-template-columns: 90px auto}
#quatation #head:not(.logo) .left{padding-top: 80px;}
#quatation.hosting #head:not(.logo) .left{padding-top: 115px;}
#quatation.receive #head:not(.logo) .left{padding-top: 80px;}
.ant-modal-body #quatation #head:not(.logo) .left{padding-top: 0;}
#quatation #head .col2{grid-template-columns: 85px max-content;}
#quatation #head .right .col2{width: 160px;grid-template-columns: 47px max-content;padding-left: 5px;white-space: nowrap;}
#quatation.hosting #head .right .col2{padding-left: 0;}
/* #quatation.invoiceprint #head .right .col2{grid-template-columns: 151px max-content} */
#quatation #table{margin-top: 5px;}
#quatation #table table{width: 100%;}
#quatation #table table th,#quatation #table table td{border: 1px solid #333}
#quatation #table table td{vertical-align: top}
/* #quatation #table table th{border: 0;vertical-align: top}
#quatation #table table thead>tr>th:first-child{border-left:2px solid #333;}
#quatation #table table td{border-top: 2px solid #333;}
#quatation #table table tbody>tr:nth-last-child(2)>td{border-bottom: 2px solid #333}
#quatation #table table tbody>tr>td:first-child{border-left: 2px solid #333}
#quatation #table table tbody>tr:last-child>td:not(:first-child){border: 2px solid #333} */


#quatation #table table tr>th:not(:first-child),#quatation #table table tr>td:not(:first-child){width: 1%;white-space: nowrap}
#quatation #table table thead>tr>th{background: #BEC0BF;color: #000;padding: 8px;font-size: 15px;}
#quatation #table .ant-form-item{margin-bottom: 0;}
#quatation #table .ant-input{padding: 7px 10px;border: 0}
#quatation #table tr>td:not(:nth-child(1)) .ant-input{text-align: right}
#quatation #table .textEditor{padding-left: 20px;background: #f6f6f6;margin: 0 5px 5px}
#quatation #table .textEditor textarea{background: #f6f6f6;min-height: 80px}
#quatation:not(.hosting) #table tr:last-child>td:first-child{border: 0 !important}
#quatation #table tr:last-child>td:not(:first-child){background: #eaeaea;}
#quatation #table tr:last-child>td>div{display:grid;grid-template-columns: auto max-content}
#quatation #table tr:last-child>td>div b{font-weight: bold;font-size: 20px;color: #000;display: block;text-align: right}
#quatation #table tr:last-child>td>div>div{padding-right: 10px;padding-top: 5px}
#quatation #table tr:last-child>td>div>div>p{font-weight: bold;font-size: 13px;}
#quatation #table tr:last-child>td>div>p{padding: 7px 0 0 10px;margin: 0;font-weight: bold;color: #000}
#quatation #table tr>td:last-child input{color: #000;font-weight: bold;}
#quatation #table tr>td:first-child input{font-weight: bold;}
#quatation.hosting #table table td{vertical-align: middle}
#quatation.hosting #table>table>tbody>tr:first-child>td{font-size: 18px;}

#quatation #table tr:first-child td textarea{font-weight: bold;}
#quatation #terms{display: grid;grid-template-columns: 50% 50%;margin-top: 20px}
#quatation #terms h2{font-family: "Mitr";font-size: 24px;font-weight: 300;margin-bottom: 5px;letter-spacing: -1px;}
#quatation #terms>div:first-child{padding-right: 50px;margin-bottom: 10px;}
#quatation #terms>div:first-child .ant-form-item{padding-left: 10px}
#quatation #terms>div:first-child .ant-form-item label{display: block;}
#quatation #terms .ant-radio-wrapper>span{display: inline-block;white-space: pre-line;vertical-align: top}
#quatation #terms .ant-radio-wrapper>span:first-child{margin-top: 4px;}
#quatation #terms ul{margin: 0;padding: 0 0 0 25px}
#quatation #terms .ant-checkbox-wrapper{margin-left: 8px;}
#quatation #signature{margin-top: 15px;}
#quatation #signature table{width: 100%;}
#quatation #signature table tr>td{border: 1px solid #333;padding: 10px}
#quatation #signature table tr>td:first-child{width: 50%}
#quatation #signature table tr:first-child>td{padding: 10px 10px 60px 10px}
#quatation #signature table tr:last-child>td:first-child{text-align: center}
#quatation #signature p{margin-bottom: 0;}
#quatation .ant-calendar-picker>div>i{display: none;}
#quatation #rowOfTable{position: relative;}
#quatation #rowOfTable .ant-input-number{border: 1px solid transparent;box-shadow: none !important;margin-bottom: 5px;position: absolute;bottom:100%;right: 0;width: 42px}
#quatation #rowOfTable .ant-input-number-input{border: 0;border-radius: 0;border: 2px dashed #000;outline: none;box-shadow: none;padding: 0 0 2px 2px;text-align: center;font-size: 30px;width: 40px;color: blue}
#quatation #rowOfTable .ant-input-number-handler-wrap{display: none !important}
#quatation #deleteQuatation{position: absolute;bottom: 12px;left: 15px;}
.ant-modal.quatationModal{top: 10px}

#quatation.invoice #signature{padding-left: 55%;margin-top: 30px;}
#quatation.invoice #signature table td{border: 0}
#quatation.invoice #signature table tbody>tr:last-child>td{border-top: 2px solid #333}
#quatation.invoice #signature table tr:first-child>td{padding: 0 0 50px}
#quatation.invoice #terms{grid-template-columns: auto}
#quatation.invoice #terms>div:first-child{margin-bottom: 15px;}
#quatation.invoice .bank{display: grid;grid-template-columns: max-content auto;align-items: top}
#quatation.invoice .banks{display: inline-block;}
#quatation.invoice .banks>li:not(:last-child){border-bottom: 1px solid #ccc;margin-bottom: 10px;padding: 0 0 10px;}
#quatation.invoice .bank>div p{margin: 0;}
#quatation.invoice .bank>img{margin: 5px 10px 0 0;}
#quatation.invoice #rowOfTable{position: absolute;bottom: 100%;margin-bottom: 5px;right: 0;width: 220px}
#quatation.invoice #rowOfTable .ant-select-selection{border: 1px solid blue}
#quatation.invoice #table.relative{position: relative;}
#quatation.invoice #table .textEditor textarea{color: #333}
#quatation.invoice #table tr:first-child td textarea{height: auto;color: #333}
#quatation.invoice #head .right .ant-input{color: blue !important}




#quatation.print{width: 900px;margin: 20px auto;background: #fff;padding: 30px 60px;min-height: 100vh}
#quatation.print .col2>p{margin-bottom: 5px;}
#quatation.print .col2>p:first-child{text-align: left;}
/* #quatation.print.receive  #head .right .col2{grid-template-columns: 165px auto} */
#quatation.print .col2>p:last-child{text-align: left;padding-left: 15px;}
#quatation.print #title{font-size: 14px;font-weight: bold;margin: 5px;padding: 0 5px}
#quatation.print.hosting #title{padding: 5px}
#quatation.print tr.title>td{background: #EAEAEA;}
#quatation.print .topic{font-weight: bold;padding: 5px 10px 0;margin-bottom: 5px;}
#quatation.print .topic>span{color: #999;font-weight: bold;margin-left: 10px;font-size: 13px;}
#quatation.print .textEditor{margin: 0 0 5px !important;padding-left: 30px !important;background: transparent !important;font-size: 14px;}
#quatation.print .textEditor ul{margin: 0;padding: 0 15px}
#quatation.print .subTotal{text-align:right;font-weight: bold;padding-right:10px;padding-top:5px}
#quatation.print #table tbody>tr>td:not(:first-child){padding: 5px 10px;text-align: right;font-weight: bold;width: 1%;white-space: nowrap}
#quatation.print #table thead>tr>th:not(:first-child){text-align: center;width: 1%;white-space: nowrap}
#quatation #table tr:last-child>td>div.phase{display: block;}
#quatation #table tr:last-child>td>div.phase>div{display: grid;grid-template-columns: auto max-content;padding: 5px 10px !important;align-items: top}
#quatation #table tr:last-child>td>div.phase>div:last-child{background: #eee;}
#quatation #table tr:last-child>td>div.phase>div p{margin: 0 !important;color: #333 !important;}
#quatation #table tr:last-child>td>div.phase>div>p:first-child{text-align: left}
#quatation #table tr:last-child>td>div.phase>div:last-child{padding-top: 10px !important;padding-bottom: 6px !important}
#quatation #table tr:last-child>td>div.phase>div:last-child>div>p{font-weight: normal;font-size: 13px;color: #444 !important}
#quatation #table tr:last-child>td.bgff{background: #fff;padding: 0 !important}

#quatation.print.receive #head2{grid-template-columns: 55% auto;display:grid;}
#quatation.print.receive #head2.col2>div:last-child{padding-left: 20px;}
#quatation.print.receive #head2.col2>div:last-child>.col2{grid-template-columns: 55px auto}
#quatation.print.receive #head2.col2>div:first-child>.col2{grid-template-columns: max-content auto}
#quatation.print.receive #head2.col2>div:first-child>.col2>p{padding-right: 15px;}
#quatation.print.receive{padding-top:50px}
#quatation.print.receive .col2>p{padding:0}
#quatation.print.receive .col2>p.pl10{padding-left:10px}

#quatation.print #table tr:last-child>td>div>p{padding: 5px 0 0;text-align: left}
#quatation.print #table tr:last-child>td>div>div{padding-right: 0;padding-top: 3px;}
#quatation.print #table tr:last-child>td>div b{font-size: 18px;}
#quatation.print #table tr:last-child>td>div>div>p{color: #777;margin-bottom: 2px;}
#quatation.print #terms ul{padding-left: 18px;}
#quatation.print #terms ul.banks{padding-left: 1px;}
#quatation.print p.switch-p{color: white}
#quatation.print .ant-switch{position: absolute;top:5px;right:5px;}
#quatation.print #signature table tr>td:first-child{position: relative;}
#quatation.print #signature table tr>td:first-child img{position: absolute;top: 20px;left: 0;right:0;margin: 0 auto}


#quatation.print.invoice{width: auto;margin: 0;padding: 0;min-height: initial}
#quatation.print.invoice .textEditor{white-space: pre-line}


#quatation.print.invoiceprint #signature{padding-left: 58%;margin-top: 30px;}
#quatation.print.invoiceprint #signature table td{border: 0}
#quatation.print.invoiceprint #signature table tbody>tr:last-child>td{border-top: 2px solid #333}
#quatation.print.invoiceprint #signature table tr:first-child>td{padding: 0 0 50px}
#quatation.print.invoiceprint #terms{grid-template-columns: auto}
#quatation.print.invoiceprint #terms>div{margin-bottom: 15px;}
#quatation.print.invoiceprint .bank{display: grid;grid-template-columns: max-content auto;align-items: top}
#quatation.print.invoiceprint .banks{display: inline-block}
#quatation.print.invoiceprint .banks>li:not(:last-child){border-bottom: 1px solid #ccc;margin-bottom: 10px;padding: 0 0 10px;}
#quatation.print.invoiceprint .bank>div p{margin: 0;}
#quatation.print.invoiceprint .bank>img{margin: 5px 10px 0 0;}
#quatation.print.invoiceprint.receive #signature{margin-top: 50px;}

#quatation #table .table-desc{padding: 10px;font-size: 12px;}
#quatation #table .table-desc>p{font-weight: bold;margin-bottom: 0;font-size: 14px;}
#quatation #table .table-desc table{width: 60%;height: auto;}
#quatation #table .table-desc table td{background: #fff;border: 0;font-size: 12px;border-top: 1px solid #ccc;vertical-align: top;padding: 2px 5px}
#quatation #table .table-desc table tr:first-child>td{font-weight: bold;}
#quatation #table .table-desc table tr:first-child>td{border-top: 0;}
#quatation #table .table-desc table tr:last-child>td{background: #eee;}
#quatation #table .table-desc table tr>td:last-child{text-align: right}
#quatation #table .table-desc>div{height: 140px;overflow: hidden;padding-left: 5px;}
#quatation #table .table-desc>div td{height: initial !important;}

#confirm-quatation{font-style: italic;margin-top: 10px;}


@media print{
    body{background: #fff !important;}
    #quatation.print .ant-switch{display: none !important}
    .hidden-print{display: none !important}
    #quatation.print{padding: 0 80px;margin: 0 auto}
    #quatation.hosting #head .right .ant-input{border: 0 !important}
    #quatation #head.c2s{padding-top: 70px;}
}