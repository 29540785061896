.login-form {
    display: block;
    width: 300px;
    margin: 30px auto !important;
}
.login-form-forgot {
    float: right;
}
.login-form-button {
    width: 100%;
}
